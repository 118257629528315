import { useHookstate } from "@hookstate/core";
import { Box, Divider, Stack, TableCell, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { langDictionary } from "../../../../../../../../configs/languageDictionaryEn";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { getInspectionBusinesData, getProfitLossAccountsData } from "../../Api/AppraisalApi";
import TableBodyWrapper from "../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { TableHeaderWrapperCustom } from "../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import { TableRowWrapper } from "../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableWrapper from "../../Helpers/TableContainer/TableWrapper/TableWrapper";
import workingCapitalRequirementPreInitiation from "./Helpers/WorkingCapitalReq";

const WorkingCapitalRequirement = ({ applicationId }) => {
    const isLoading = useHookstate(true);
    const isVisible = useHookstate(true);
    const data = useHookstate({});
    const headerTitles = useHookstate([]);
    const { t: translate } = useTranslation();
    const selectedYears = useHookstate<string[]>([]);
    const selectedYear = useHookstate<number>(moment().year());

    const componentPreInitializer = async () => {
        try {
            let [result, { totals = undefined }] = await Promise.all([getInspectionBusinesData(applicationId), getProfitLossAccountsData(applicationId)]);

            if (!result || !Object.values(result).length || !totals) {
                throw new Error("No data found");
            }

            result = workingCapitalRequirementPreInitiation({ busineeData: result, profitAndLossTotals: totals });

            if (result.status === -1) {
                throw new Error("Invalid data");
            }

            data.set(result.data);
            selectedYears.set(Object.keys(result.data));
            headerTitles.set(result.selectedYears.map((headerTitle) => translate(langDictionary?.[headerTitle])));
        } catch (error) {
            isVisible.set(false);
        } finally {
            isLoading.set(false);
        }
    }

    useEffect(() => {
        componentPreInitializer();
    }, []);

    if (isLoading.get()) {
        return <Box sx={{ height: "200px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgressComponent size={25} />
        </Box>
    }

    if (!isVisible.get()) {
        return <></>
    }

    return (
        <div>
            <Stack direction={'row'} textAlign={'center'} p={1} spacing={0.5}>
                {selectedYears.get().map((year) => {
                    const isSelectedYear = selectedYear.get() === Number(year);

                    return <Stack key={year} flex={1} width={'100%'}>
                        <ButtonComponent variant={isSelectedYear ? "contained" : "outlined"} onClick={() => selectedYear.set(Number(year))} title={year} />
                    </Stack>
                })}
            </Stack>

            <Divider />

            <TableWrapper>
                <TableHeaderWrapperCustom headerArray={headerTitles.get()} style={{ textAlign: 'center' }} />
                <TableBodyWrapper>
                    {data?.[Number(selectedYear.get() || moment().year())].get().columns.map((data: any) => <BuildRow key={`build-row-${data.dataId}`} data={data} />)}
                </TableBodyWrapper>
            </TableWrapper>
        </div>
    )
}

export default WorkingCapitalRequirement

const BuildRow = ({ data }) => {
    const getValue = (value: any) => Boolean(value) ? formatCurrency(value || 0) : '-';
    const isTotal = Boolean(data.isTotal);

    return <TableRowWrapper key={`table-row-wrapper-${data.dataId}`}>
        <TableCell key={`${data.description}-${data.dataId}`}>
            <Typography variant="subtitle1" color={"gray"} style={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}> {data.description}</Typography>
        </TableCell>
        <TableCell key={`${data.description}-${data.dataId}`} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle1" color={"gray"} px={5} style={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}>{getValue(data.numberOfDays)}</Typography>
        </TableCell>
        <TableCell key={`${data.description}-${data.dataId}`} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle1" color={"gray"} px={5} style={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}>{getValue(data.rawMaterial)}</Typography>
        </TableCell>
        <TableCell key={`${data.description}-${data.dataId}`} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle1" color={"gray"} px={5} style={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}>{getValue(data.operatingCost)}</Typography>
        </TableCell>
        <TableCell key={`${data.description}-${data.dataId}`} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle1" color={"gray"} px={5} style={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}>{getValue(data.total)}</Typography>
        </TableCell>
    </TableRowWrapper>

}