import { Downgraded } from "@hookstate/core";
import { creditData, creditDetails } from "../../configs/mainStore";
import AppConfig from "../AppConfig";

export const ProductType = { SME: "SME", MICRO: "MICRO", COMMERCIAL: "COMMERCIAL" };

export const getNonRetailProductTypes = (): number[] => {
  return AppConfig?.config?.nonRetailProductTypes || [2, 21, 22];
}

function isProductOf({ primaryApplicant = true, types = [] }: { primaryApplicant?: boolean; types?: any[] } = {}) {
  let status = false;
  let typeList: any = types;
  let nonRetail: any = typeList.includes(ProductType.SME) || typeList.includes(ProductType.MICRO) || typeList.includes(ProductType.COMMERCIAL);

  try {
    let currentProductCatalog: string;
    currentProductCatalog = productCatalogGetter();
    const { athamaruCatalog } = AppConfig.config;
    let [type, sector, scheme] = currentProductCatalog.split("-");

    if (!getNonRetailProductTypes().includes(parseInt(type))) {
      nonRetail = false;
    } else {
      nonRetail = true;
    }

    if (!primaryApplicant) {
      status = false;
    } else if (!athamaruCatalog) {
      status = false;
    } else if (currentProductCatalog === athamaruCatalog) {
      status = true;
    } else if (nonRetail) {
      status = true;
    } else {
      status = false;
    }
  } catch (error) {
    status = false;
  } finally {
    return status;
  }
}

export function isSectorOf({ primaryApplicant = true, sector = [] }: { primaryApplicant?: boolean; sector?: any[] } = {}) {
  let status = false;
  const { sectorMap } = AppConfig.config;
  try {
    let currentProductCatalog: string;
    currentProductCatalog = productCatalogGetter();
    let [type, sector, scheme] = currentProductCatalog.split("-");
    if (primaryApplicant && sectorMap && Array.isArray(sectorMap) && sectorMap.includes(parseInt(sector))) {
      status = true;
    } else {
      status = false;
    }
  } catch (error) {
    status = false;
  } finally {
    return status;
  }
}

const productCatalogGetter = () => {
  let { type, sector, scheme }: any = creditDetails.attach(Downgraded).get();
  if (!scheme && !sector) {
    let result = creditData?.formData["creditData"].attach(Downgraded).get();
    type = result.type;
    sector = result.sector;
    scheme = result.scheme;
  }

  if (!scheme) {
    return "";
  }

  return [type, sector, scheme].join("-");
};


export const microSmeAthmaruValidator = ({ currCatalog }: { currCatalog: string }): boolean => {
  const getWithoutFirstElement = (stringKey: string): string => {
    const splitedArray: string[] = stringKey.split("-");
    splitedArray.shift();
    return splitedArray.join("-");
  }

  let configAthmaruCatalog: string = getWithoutFirstElement(AppConfig.config.athamaruCatalog);
  let currProductCatalog: string = getWithoutFirstElement(currCatalog);

  return configAthmaruCatalog === currProductCatalog;
}

export const isIncludefieldLevelMandatory = ({ dataKey, productCatalog }: { dataKey: string; productCatalog?: string }) => {
  let fieldVisibility = false;
  const appConfigurations = AppConfig?.config || undefined;

  if (!dataKey || !appConfigurations) return fieldVisibility;

  if (!productCatalog) {
    productCatalog = productCatalogGetter();
  }

  const { underWrittingPolicyConfigs = undefined } = appConfigurations;

  if (!underWrittingPolicyConfigs || !underWrittingPolicyConfigs?.[productCatalog] || !underWrittingPolicyConfigs?.[productCatalog]?.fieldLevelMandatory) return fieldVisibility;

  const { fieldLevelMandatory } = underWrittingPolicyConfigs?.[productCatalog];

  fieldVisibility = fieldLevelMandatory?.[dataKey] ? true : false;

  return fieldVisibility;
}

export default isProductOf;
