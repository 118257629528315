import React, { useEffect, useState } from "react";

import { Downgraded, useHookstate } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { creditData, renderingDetails, tabValidationData, workflowActionModal } from "../../../../configs/mainStore";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../CommunicationService/CMTextInput";
import { closeGlobalModal, openGlobalModal } from "../../GlobalModal/GlobalModal";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { taskStatus } from "los-util";
import { configuredRevertableStages } from "../../../../services/apiCalls";
import CachingStorage from "../../../../services/cacheStorage";
import { getProductStageDeEvaluation } from "../../../../services/configurationApis";
import { addTasks } from "../../../../services/creditFileApiCall";
import {
  getApplicationWithdrawStages,
  updateApplicationWorkflowStage,
  validateWorkflowStage,
} from "../../../../services/workflowApiCalls";
import { getWorkflowStageById } from "../../../../services/workflowServices";
import { WorkflowOverviewActionIcons } from "../../../../styles";
import AppConfig from "../../../../utility/AppConfig";
import { currentCatalog } from "../../../../utility/helpers/creditFileUtility";
import { notCompleteSuccessCode, responseSuccessCode } from "../../../../utility/other";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster, addWfTransferToaster } from "../../GlobalToast";

export const WorkflowActionConfirmModal = () => {
  const tabValidations: any = useHookstate(tabValidationData);
  const renderingDivDetails = useHookstate(renderingDetails);
  const creditFileData: any = useHookstate(creditData);
  const [onValidate, setOnValidate] = useState(false);
  const { workflowActionData }: any = workflowActionModal.attach(Downgraded).get();
  const [dropdownList, setDropdownList] = React.useState([]);
  const [revertStage, setRevertStage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isRevert, setIsRevert] = React.useState<any>(false);
  const [isCheckedTask, setIsCheckedTask] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>({
    stage: "",
    comment: "",
  });
  const initialData = { title: "", description: "", user: "" };
  const taskState: any = useHookstate<any>(initialData);
  const downgradedAllDetails: any = creditFileData.attach(Downgraded).get();
  const { currentAssignee, currentWFStage, applicationId } = downgradedAllDetails;
  const [descriptions, setDescriptions] = useState([]);

  const content = JSON.parse(sessionStorage.getItem(applicationId + "_note_cache") ?? "{}");
  const [comment, setComment] = React.useState(content?.note || "");

  useEffect(() => {
    prepareLayout();
  }, []);

  const prepareLayout = async () => {
    const configs = (await AppConfig.config) || {};
    let sanitizedList: any = [];

    if (workflowActionData?.revertStages) {
      const { type, sector, scheme } = downgradedAllDetails?.formData?.creditData;
      const productCatelog = `${type}-${sector}-${scheme}`;

      let tempDropdownElement: any = [];

      workflowActionData?.revertStages?.map((item) => {
        let tempStage = getWorkflowStageById(item);

        if (workflowActionData?.revertStagesFromHistory?.includes(item)) {
          sanitizedList.push({
            id: tempStage?.id,
            name: tempStage?.label,
          });
        }

        if (tempStage?.id) {
          tempDropdownElement.push({
            id: tempStage?.id,
            name: tempStage?.label,
          });
        }
      });

      const _stages = [
        configs.withdrawStage,
        configs.rejectedStage,
      ];

      // Use this if there is an issue in dropdown issue

      // const _stages = [
      //   configs.withdrawStage,
      //   configs.rejectedStage,
      //   ...(configs.withdrawStages || []),
      //   ...(configs.rejectedStages || []),
      // ];

      //  end

      const { data: revertableStages } = await configuredRevertableStages({ stageId: currentWFStage });

      if (revertableStages?.length > 0) {
        const items = revertableStages.map((obj) => getWorkflowStageById(obj));
        setDropdownList(items?.length > 0 ? items : tempDropdownElement);
      } else if (_stages.includes(currentWFStage)) {
        let items = await getApplicationWithdrawStages(applicationId[0]);
        items = items.map((obj) => getWorkflowStageById(obj));
        setDropdownList(items);
      } else if (configs.postDisburesed?.includes(currentWFStage)) {
        let disbursedStageId = configs.disbursed[0];
        let stageOrderData: any = {};
        let currentPriductStages: any[] = [];
        let dropDownElements: any = [];
        let afterDisbursedStages: any[] = [];
        let revertibleRange: any[] = [];

        stageOrderData = CachingStorage.read(CachingStorage.Keys.STAGE_ORDER);

        if (stageOrderData != null) {
          currentPriductStages = stageOrderData[productCatelog];
        }

        if (currentPriductStages.length > 0) {
          if (currentPriductStages.indexOf(disbursedStageId) >= 0) {
            let disbursedIndex = currentPriductStages.findIndex((id) => {
              return id == disbursedStageId;
            });

            afterDisbursedStages = currentPriductStages.slice(disbursedIndex + 1, currentPriductStages.length);

            if (afterDisbursedStages.length > 0) {
              let currentWfIndex = afterDisbursedStages.findIndex((id) => {
                return id == currentWFStage;
              });

              revertibleRange = afterDisbursedStages.slice(0, currentWfIndex);
            }
          }
        }

        if (revertibleRange.length > 0) {
          dropDownElements = revertibleRange.map((stage) => getWorkflowStageById(stage));
        }

        setDropdownList(dropDownElements);
      } else {
        setDropdownList(sanitizedList.length > 0 ? sanitizedList : tempDropdownElement);
      }
      setIsRevert(true);
    }

    //loan product
    const product = currentCatalog();

    const { actions, stages } = !CachingStorage.read(CachingStorage.Keys.STAGE_DE + product) ? await getProductStageDeEvaluation(product) : CachingStorage.read(CachingStorage.Keys.STAGE_DE + product) || CachingStorage.read(CachingStorage.Keys.STAGE_DE);
    const { workflowStage: stage, action: { id: action } } = workflowActionData;

    //wf action validation config
    if (!actions?.length || !action) return;

    //if application workflow id should be validate
    if (actions?.includes(action)) {
      //if application workflow stage should be validate
      if (stages?.includes(stage)) {
        triggerValidations();
      }
    }
  }

  useEffect(() => {
    const text = taskState?.description.get();
    const descriptionList = text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");
    setDescriptions(descriptionList);
  }, [taskState?.description.get()]);

  const triggerValidations = async () => {
    setOnValidate(true);
    const response: any = await validateWorkflowStage(workflowActionData?.applicationId, null);
    setOnValidate(response?.status == -1 ? false : true);

    if (response?.status == -1) {
      addToaster({
        status: "error",
        title: "Error !",
        message: response.data,
      });
      closeGlobalModal();
    } else {
      setOnValidate(false);
    }
  };

  const launchModal = (result, wfDataObject) => {
    const componentType = result?.msg?.workFlowStatus?.data?.componentId;
    const jobData = result?.msg?.workFlowStatus?.data;

    let modalSize = "sm";
    if (componentType === "component2") {
      // if trial calculator prompt
      modalSize = "lg";
    }

    openGlobalModal({
      modalSize: modalSize,
      title: "",
      bodyId: "workflow-ui-job-handler",
      close: false,
      modalParams: { wfData: wfDataObject, jobResData: jobData },
    });
  };

  const onSubmit = async () => {
    let isValid = true;
    taskState.user.set(currentAssignee);

    let tempError = errorMessage;
    if (comment === "") {
      tempError = { ...tempError, ...{ comment: "Required!" } };
      isValid = false;
    }

    if (isRevert && revertStage === "") {
      tempError = { ...tempError, ...{ stage: "Required!" } };
      isValid = false;
    }

    setErrorMessage(tempError);

    if (isValid) {
      setIsLoading(true);

      let actionId = workflowActionData?.action?.id;

      if (isRevert) {
        actionId = "revert";
      }
      const wfDataObject = {
        applicationId: workflowActionData?.applicationId,
        workflowStage: workflowActionData?.workflowStage,
        action: actionId,
        workflowId: workflowActionData?.workflowId,
        productId: workflowActionData?.productId,
        applicantTypeId: workflowActionData?.applicantTypeId,
        comment: comment,
        revertStage: revertStage,
      };

      const result: any = await updateApplicationWorkflowStage(
        workflowActionData?.applicationId,
        workflowActionData?.workflowStage,
        actionId,
        workflowActionData?.workflowId,
        workflowActionData?.productId,
        workflowActionData?.applicantTypeId,
        comment,
        revertStage
      );
      tabValidations.set(JSON.parse(JSON.stringify(result)));

      try {
        let { data: assignee } = result?.msg?.workFlowStatus?.data;
        let errorState: any = await onTaskHandler(assignee);

        if (errorState) {
          return;
        }
      } catch (error) { }

      closeGlobalModal();

      if (result?.error) {
        return addToaster({
          status: "error",
          title: "Validation Fail",
          message: result.error.length > 0 ? result.error : "Something went wrong",
        });
      }

      const { status } = result?.msg?.workFlowStatus;

      if (status === responseSuccessCode) {
        // UI job handle
        if (result?.msg?.workFlowStatus?.data?.componentId) {
          launchModal(result, wfDataObject);
        } else {
          addWfTransferToaster({
            status: "success",
            title: result?.msg?.workFlowStatus?.data,
            message: "Application " + workflowActionData?.applicationId + " actioned",
            applicationId: workflowActionData?.applicationId
          });

          // isChanged.set(!isChanged.get());
          // after successfully workflow change the application is navigated to the application list
          AppConfig.historyPage = renderingDivDetails?.renderId?.get();

          renderingDivDetails.set({
            renderId: "manage-applications",
            renderParams: {},
          });
        }
      } else if (result?.msg?.workFlowStatus?.status == notCompleteSuccessCode) {
        addToaster({
          status: "warning",
          title: "Warning !",
          message: result?.msg?.workFlowStatus?.data,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error !",
          message: result?.msg?.workFlowStatus?.data,
        });
      }

      setIsLoading(false);
    }
  };

  const onTaskHandler = async (assignee) => {
    let errorState = false;

    const taskStat = Object.keys(taskStatus)?.find((sts) => taskStatus[sts]?.key === "open");
    if (isCheckedTask) {
      const descriptionArray = descriptions.map((description, index) => ({
        description: description,
        title: `Task for correction`,
      }));

      const data = {
        applicationId: workflowActionData?.applicationId,
        tasks: descriptionArray,
      };
      const res = await addTasks(data);
    }

    return errorState;
  };

  const onChangeField = (value) => {
    setComment(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ comment: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ comment: "" } });
    }
  };

  const onChangeStage = (e: any) => {
    setRevertStage(e.target.value);

    if (e.target.value === "") {
      setErrorMessage({ ...errorMessage, ...{ stage: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ stage: "" } });
    }
  };

  const handleDeleteDescription = (index) => {
    const updatedDescriptions = [...descriptions];

    updatedDescriptions.splice(index, 1);

    setDescriptions(updatedDescriptions);

    const updatedText = updatedDescriptions.join("\n");
    taskState?.description.set(updatedText);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-14 basic-font-color-bold">{`${workflowActionData?.action ? workflowActionData?.action?.name : "Revert"
          } Action`}</div>
      </Stack>

      {onValidate ? (
        <Box m={2} display="flex" alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Box ml={2}>Validation in progress for workflow transition....</Box>
        </Box>
      ) : (
        <Grid container spacing={1} mt={1} rowSpacing={1} className="modal-text-field">
          {isRevert && (
            <Grid item xs={12} md={12} className="">
              <SelectComponent
                label={"Revert Stage"}
                value={revertStage}
                values={dropdownList}
                onChange={(e) => {
                  onChangeStage(e);
                }}
                required={isRevert}
                disabled={isLoading}
              />
              {errorMessage?.stage !== "" && (
                <Box>
                  <span className="error font-size-14">{errorMessage?.stage}</span>
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} className="">
            <CMTextInput
              desc={"Comment*"}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    onChangeField(e.target.value);
                  }}
                  multiline={true}
                  minRows={6}
                  value={comment}
                  disabled={isLoading}
                />
              }
              error={errorMessage?.comment !== "" ? true : false}
            />
          </Grid>
          {isRevert && (
            <Grid container item alignItems={"center"}>
              <CheckBoxComponent
                checked={isCheckedTask}
                onChange={() => {
                  setIsCheckedTask(!isCheckedTask);
                }}
              />
              <Typography variant="subtitle2" color={"gray"}>
                Add Task (You can separate tasks by using the Enter key)
              </Typography>
            </Grid>
          )}
          {isCheckedTask && (
            <Grid container columns={12} item spacing={2} xs={12}>
              <Grid item xs={6}>
                <TextBoxComponent
                  onChange={(e) => {
                    taskState?.description?.set(e.target.value);
                  }}
                  multiline={true}
                  minRows={3}
                  value={taskState?.description.get()}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                {descriptions.map((description, index) => (
                  <Stack direction="row" p={0.5} flex={1}>
                    <Stack direction="row" flex={1}>
                      <Typography variant="subtitle1" key={index}>
                        👉 {description}
                      </Typography>
                    </Stack>
                    <DeleteIcon
                      color="primary"
                      onClick={() => handleDeleteDescription(index)}
                      style={WorkflowOverviewActionIcons}
                    />
                  </Stack>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="error"
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};
