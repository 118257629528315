import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";
const api = Api.getApi();

const getSCVRecoveryUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/scv-recovery-details`;
const scvDetailsUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/scv-client-details`;
const scvDetailsUrlOnRefresh = `${URL.REACT_APP_BACKEND_HOST}/bank-service/scv-client-details?force=`;
const clientDetailsUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/getClientDetails`;
const groupSelectionUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/groups/selection`;
const getGroupMembersUrl = `${URL.REACT_APP_BACKEND_HOST}/los/applications/groups`;
const clientsDetailsUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/getClientsDetails`;
const getPreviousBurrowing = `${URL.REACT_APP_BACKEND_HOST}/bank-service/previous-burrowing?force=`;
const loanReportDataUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/loan-report-data`;
const coreBankDataUrl = `${URL.REACT_APP_BACKEND_HOST}/bank-service/core-bank-data`;
const coreBankSchemesForceUpdate = `${URL.REACT_APP_BACKEND_HOST}/bank-service/cbs/schemes/force`;
const systemCacheUpdate = `${URL.REACT_APP_BACKEND_HOST}/`;
const apiStatusBankDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/getApiStatus`;
const apiResetBankDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/reset/disbursement/log`;
const coreBankExtractionMethodUrl = `${URL.REACT_APP_BACKEND_HOST}/los/notifications/user/accounts`;

export const getSCVRecoveryDetails = async (searchValue, type = "CIF", refresh = false, searchFor = "", applicationId = null) => {
  let formattedUrl: any = getSCVRecoveryUrl;

  if (refresh == true) {
    formattedUrl = `${formattedUrl}?force=${true}`;
  }

  try {
    if (!searchValue) {
      return { status: 1, data: [] };
    }

    let payload: any = {
      searchKey: type,
      searchValue: searchValue,
      applicationId,
    };

    searchFor && (payload.searchFor = searchFor);

    const { data } = await api.post(`${formattedUrl}`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const getSCVClientDetails = async (value, force = false, applicationId = null, key = null) => {
  try {
    let url = `${scvDetailsUrl}`;
    if (force) {
      url = `${scvDetailsUrl}?force=${force}`;
      CachingStorage.clear(`${value}-SCVClientDetails`);
    }

    let searchKey: any = key;
    if (!searchKey) {
      if (typeof value === "string") {
        searchKey = value.length < 9 ? "CIF" : "NIC";
      } else {
        searchKey = value?.[0]?.length < 9 ? "CIF" : "NIC";
      }
    }

    if (!value) {
      return {};
    }

    const data = await CachingStorage.invoke(`${value}-SCVClientDetails`, () => api.post(url, { searchKey, searchValue: value, applicationId }));

    return data;
  } catch (error) {
    return {};
  }
};

export const getSCVClientDetailsOnRefresh = async (value, applicationId) => {
  try {
    if (!value) {
      return null;
    }

    const { data } = await api.post(`${scvDetailsUrlOnRefresh}${true}`, {
      searchKey: value.length < 9 ? "CIF" : "NIC",
      searchValue: value,
      applicationId,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getClientDetails = async (nic) => {
  try {
    const { data } = await api.post(`${clientDetailsUrl}`, {
      searchKey: "NIC",
      searchValue: nic,
    });
    return data;
  } catch (error) { }
};

export const getClientDetailsV2 = async (searchValue, force = false, applicationId, key = null) => {

  try {
    let url = `${clientDetailsUrl}`;
    if (force) {
      url = `${clientDetailsUrl}?force=${force}`;
    }

    let searchKey: any = key;
    if (!searchKey) {
      if (typeof searchValue === "string") {
        searchKey = searchValue.length < 9 ? "CIF" : "NIC";
      } else {
        searchKey = searchValue?.[0]?.length < 9 ? "CIF" : "NIC";
      }
    }

    const { data } = await api.post(url, {
      searchKey,
      searchValue,
      applicationId,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const saveSelectedGroup = async (group, applicationId) => {
  const { CIF } = group;
  try {
    const { data } = await api.post(`${groupSelectionUrl}`, {
      CIF,
      applicationId
    });
    return data;

  } catch (error) {
    throw error;
  }
}

export const getApplicationsGroupsSelection = async (applicationId: any) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/groups`, {
      applicationId
    });
    return data ?? {};
  } catch (error) {
    throw error;
  }
}
export const searchApplicationsGroupsSelection = async ({ nic }) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/groups/search`, {
      nic
    });
    return data ?? undefined;
  } catch (error) {
    throw error;
  }
}

export const getClientsDetails = async (nicArray, force = false) => {
  if (!nicArray || nicArray.length === 0) {
    return {};
  }

  let formattedUrl: any = clientsDetailsUrl;

  if (force == true) {
    formattedUrl = `${formattedUrl}?force=${true}`;
  }

  try {
    const { data } = await api.post(`${formattedUrl}`, {
      searchKey: "NIC",
      searchValueArray: nicArray,
    });
    return data;
  } catch (error) { }
};

export const getPreviousBurrowingDetails = async (nic, force, applicationId = null) => {
  try {
    let getPreviousBurrowingQuery = getPreviousBurrowing;

    if (force) {
      getPreviousBurrowingQuery = `${getPreviousBurrowing}${force}`;
    }

    const { data } = await api.post(`${getPreviousBurrowingQuery}`, {
      searchKey: "NIC",
      searchValue: nic,
      applicationId,
    });
    return data;
  } catch (error) { }
};

export const setLoanReportDataChanges = async (dataObject) => {
  try {
    const data = await api.post(`${loanReportDataUrl}`, {
      setData: dataObject,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const setCoreBankData = async (applicationId, key, setData) => {
  try {
    const data = await api.patch(`${coreBankDataUrl}`, {
      applicationId,
      key,
      setData,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const setCoreBankDataObject = async (applicationId, object) => {
  try {
    const data = await api.patch(`${coreBankDataUrl}`, {
      applicationId,
      object,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCoreBankData = async (applicationId, key) => {
  try {
    const data = await api.post(`${coreBankDataUrl}`, { applicationId, key });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDisbursementAPIStatusData = async (applicationId) => {
  try {
    const { data } = await api.get(`${apiStatusBankDataUrl}`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const resetDisbursementAPIStatusData = async (applicationId) => {
  try {
    const res = await api.delete(`${apiResetBankDataUrl}`, {
      applicationId
    });
    return res;
  } catch (error) {
    return null;
  }
};

export const getClientDetailsByCIF = async (cif) => {
  try {
    const { data } = await api.post(`${clientDetailsUrl}`, {
      searchKey: "CIF",
      searchValue: cif,
    });
    return data;
  } catch (error) { }
};

export const forceUpdateCBSSchemes = async () => {
  try {
    const { data } = await api.get(`${coreBankSchemesForceUpdate}`);
    return data;
  } catch (error) { }
};

export const forceUpdateSystemCache = async ({ type, mode }) => {
  try {
    if (type === "wf") {
      type = "workflow-engine";
    }
    if (type === "dm") {
      type = "document-management";
    }
    if (type === "utility") {
      type = "los/utility";
    }

    const url = `${systemCacheUpdate}${type}/caches/reset?type=${mode.trim(",")}`;
    const res = await api.get(url);
    return res;
  } catch (error) { }
};

export const getExtractionMethod = async () => {
  try {
    const response = await api.get(`${coreBankExtractionMethodUrl}`);
    return response.data || response;
  } catch (error) { }
};

export const updateExtractionMethod = async (Obj) => {
  try {
    const { data } = await api.put(`${coreBankExtractionMethodUrl}`, Obj);
    return data;
  } catch (error) { }
};
