import { Downgraded, useState } from "@hookstate/core";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { uploadCauHubDepDoc } from "../../../../../services/creditFileApiCall";
import { getWorkflowStageById } from "../../../../../services/workflowServices";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import ActionComponent from "./ActionComponent";

const CAUHDItemRow = ({ data, previewBtnClicked, handleCallBackOnAction }) => {
  const [historyNote, setHistoryNote] = React.useState<any>("")
  const [showExpandButton, setShowExpandButton] = React.useState<any>(false);
  const [openNoteModal, setOpenNoteModal] = React.useState<any>(false);


  const { wf_system_users, wf_groups }: any = useState(workflowMasterData);

  const isExpand = useState(false);
  const createdDate = data.createdAt ? dateFormatWithTimeChange(data.createdAt) : "";
  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == data?.createdBy);
  const stage = data?.workflowStage ? getWorkflowStageById(data?.workflowStage) : "";

  let { userId }: any = userDetails.attach(Downgraded).get();

  const callBackOnAction = (data) => {
    handleCallBackOnAction(data);
  };

  let assignPermission = false;

  if (data.assignees?.length > 0) {
    if (data.assignees.includes(userId) !== true) {
      assignPermission = true;
    } else if (userId !== parseInt(data.assignedUsers)) {
      assignPermission = true;
    } else {
      assignPermission = false;
    }
  }

  let assignees: any = [];

  const _assignedGroups = data?.assignedGroups || "";

  const assignedGroups = _assignedGroups.split(",") ?? "";
  const assignedUsers = data?.assignedUsers;

  if (assignedUsers) {
    const getAssignedUserData = wf_system_users?.get()?.find((usr) => usr?.id == Number(assignedUsers));

    assignees.push(getAssignedUserData?.fullName);
  } else {
    if (assignedGroups.length > 0) {
      for (const item of assignedGroups) {
        const assigneeData = wf_groups?.get()?.find((group) => group?.id === Number(item));
        assignees.push(assigneeData?.name);
      }
    }
  }

  const title = data?.title ? data?.title : data?.note;

  const _limitedNote = data.note.length <= 100 ? data.note : data.note.substr(0, 100) + "...";

  useEffect(() => {
    setHistoryNote(_limitedNote);

    if (data.note?.length > 100) {
      setShowExpandButton(!showExpandButton);
    }
  }, [_limitedNote, data.note]);

  const expandHistoryNote = () => {
    openGlobalModal({
      modalSize: "xl",
      title: `${data.title}`,
      bodyId: "view-referral-note",
      close: true,
      modalParams: {
        data,
      },
    });
  };

  const viewHistory = (data) => {
    openGlobalModal({
      modalSize: "xl",
      title: `${data.title}`,
      bodyId: "view-referral-history",
      close: true,
      modalParams: {
        data,
        currentUser: userId
      },
    });
  };

  return <Stack>
    <Paper sx={{ padding: 2, margin: 0 }}>
      <Stack>
        <Stack direction={"row"}>
          <Stack flex={1} px={2}>
            <Typography variant="body1" >
              {title}
            </Typography>
            <Typography variant="subtitle1" color={"grey"} sx={{ whiteSpace: "pre-wrap" }}>
              {historyNote}
              {showExpandButton && (
                <Stack direction={"row-reverse"}>
                  <ChipsComponent
                    size={"large"}
                    label={<Typography variant="caption">View More</Typography>}
                    onClick={() => expandHistoryNote()}
                  ></ChipsComponent>
                </Stack>
              )}
            </Typography>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack width={350}>
            <ActionComponent data={data} callBackOnAction={callBackOnAction} assignPermission={assignPermission} documentUploadApi={uploadCauHubDepDoc} />
            <Divider />
            <Stack direction={"row"} py={1} justifyContent={"center"}>
              <div className="basic-font font-size-14 label enable-label">{stage?.name}</div>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ marginBottom: '10px' }} />

        <Stack direction={"row"}>
          <Stack flex={1} direction={"row"} justifyContent={"space-between"} px={2}>
            {[
              { key: "Assignee", value: assignees.join(", ") },
              { key: "Assigned At", value: dateFormatWithTimeChange(data.actionedAt) },
              { key: "Created By", value: createdBy?.fullName },
              { key: "Created Date", value: createdDate },
            ].map((item) => (
              <Stack>
                <div className={`basic-font basic-font-color opacity-7 font-size-12`}>{item.key}</div>
                <div className={`basic-font basic-font-color font-size-14`}>{item.value}</div>
              </Stack>
            ))}
          </Stack>
          {
            data?.historyCount !== 0 && (<Stack width={350} direction={"row-reverse"}>
              <Button
                variant="outlined"
                onClick={() => {
                  viewHistory(data);
                }}
              >
                History
              </Button>
            </Stack>)
          }
        </Stack>
      </Stack>
    </Paper>
  </Stack>;
};

export default CAUHDItemRow;