import URL from "../configs/connection";
import { endpoints } from "../configs/endpoints";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";

const api = Api.getApi();

export const saveBranchById = async (data) => {
  const obj = {
    userId: data.userId,
    branchList: data.branchList,
  };
  try {
    const res = await api.post(`${endpoints.saveUserBranches}`, obj);
    return res;
  } catch (error) { }
};

export const addConditiontype = async (payload) => {
  try {
    CachingStorage.clear("/condition/type");
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/condition/type`, payload instanceof Array ? payload : [payload]);
    return res;
  } catch (error) {
    return { status: -1 };
  }
};

export const getConditionList = async () => {
  try {
    const res = await CachingStorage.invoke("/condition/type", () => api.get(`${URL.REACT_APP_BACKEND_HOST}/los/condition/type`));

    return res;
  } catch (error) {
    return { status: -1 };
  }
};

export const saveDLALimitsById = async (data) => {
  const obj = {
    catalogKey: data.catalogKey,
    applicantType: 1,
    userId: data.userId,
    dlaLimitMin: data.dlaLimitMin,
    dlaLimitMax: data.dlaLimitMax,
  };
  try {
    const res = await api.post(`${endpoints.saveDLALimits}`, obj);
    return res;
  } catch (error) { }
};

export const saveInspectionLimitsById = async (data) => {
  try {
    const res = await api.post(`${endpoints.saveInspectionLimits}`, data);
    return res;
  } catch (error) {
    return {
      status: -1,
    };
  }
};

export const fetchInspectionLimits = async (data) => {
  try {
    if (!data || !data.scheme || !data.groups) {
      throw new Error("Failed to process request.");
    }
    const res = await api.get(`${endpoints.saveInspectionLimits}?scheme=${data.scheme}&groups=${data.groups.join(",")}`);
    return res;
  } catch (error: any) {
    return {
      message: error?.message || "",
      status: -1,
    };
  }
};

export const fetchUsersBranches = async (userId) => {
  try {
    const response = await api.get(endpoints?.fetchUserBranches, {
      userId,
    });
    return response;
  } catch (error) { }
};

export const usersInBranchesAndGroupsManagedByLoggedUser = async (payload) => {
  try {
    const { data } = await api.post(endpoints?.allUsersManagedByMe, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const groupUserPrivilegUpdates = async (data) => {
  try {
    // const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/auth/users/privileges`, data);
    return {};
  } catch (error) { }
};

export const savePrivilegeGroupsById = async (data) => {
  const obj = {
    userId: data.userId,
    groupIds: data.groupIds,
  };
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/auth/users/${data.userId}/privileges`, obj);
    return response;
  } catch (error) { }
};

export const fetchPrivilegeGroups = async (userId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/auth/users/${userId}/privileges`);

    return response;
  } catch (error) { }
};

export const fetchUsersDlaLimits = async (userId) => {
  try {
    const response = await api.get(endpoints?.fetchDLALimits, {
      userId,
    });
    return response;
  } catch (error) { }
};

export const updateApplicationAssignee = async (req) => {
  try {
    const res = await api.patch(`${URL.REACT_APP_BACKEND_HOST}/los/update-current-assignee`, req);

    return res;
  } catch (error) {
    return null;
  }
};

export const manageWorkflowReferral = async (workflowId, productScheme, workflowStage) => {
  try {
    const response = await api.get(endpoints?.manageWorkflowReferral, {
      workflowId,
      productScheme,
      workflowStage,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const saveWorkflowReferral = async (data) => {
  try {
    const response = await api.post(`${endpoints.manageWorkflowReferral}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const createPredefinedConditions = async (data, type) => {
  try {
    const response = await api.post(`${endpoints.addPredefinedCondition}?type=${type}`, data);

    return response;
  } catch (error) {
    return null;
  }
};

export const updatePredefinedConditions = async (data) => {
  try {
    const response = await api.put(endpoints.addPredefinedCondition, data);

    return response;
  } catch (error) {
    return null;
  }
};

export const createNewChargeType = async (data) => {
  try {
    if (data instanceof Object) {
      data = [data];
    }
    const response = await api.post(`${endpoints.addNewChargeType}`, data);
    if (response?.status === 1) {
      return response?.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const updateMasterDataTitle = async (data) => {
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/masterdata/meta`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateUserGroupConfig = async (data, type) => {
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/auth/user-group-config?type=${type}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const unlinkUserGroupConfig = async (data) => {
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/auth/groups/assignments/unlink`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateApplicationSectionDocumentMap = async (data) => {
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/applications/sections/documents/map`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateDocumentTypeMap = async (data) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/documents/catalog`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateScoreCardMap = async (data) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/configuration`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getProductScorecardTemplates = async (queryParams) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards`, queryParams);
    if (response?.status === 1) {
      return response?.data;
    }
  } catch (error) { }
};

export const updateUnderwritingPolicy = async (data) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/configurations/policies/underwriting`, data);
    return response;
  } catch (error) {
    return null;
  }
};
export const getUnderwritingPolicyConfig = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/configurations/policies/underwriting`);
    return response;
  } catch (error) {
    return null;
  }
};

export const addNewDocumentType = async (data) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/dcuments/section`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationSectionDocumentMap = async (params) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/sections/documents/map`, {
      workflowId: params?.workflowId,
      productCatalog: params?.productCatalog,
      sectionKey: params?.sectionKey,
      participantType: params?.participantType,
    });

    return response;
  } catch (error) { }
};

export const getStageRoleBasedConfigurations = async (params) => {
  try {
    if (sessionStorage.getItem("stagesRoles")) {
      return JSON.parse(sessionStorage.getItem("stagesRoles")!).data;
    }
    const response = await api.get(endpoints.stageRoles + params.workflowId);
    if (response.status === 1) {
      sessionStorage.setItem("stagesRoles", JSON.stringify(response));
      return response.data || [];
    }
    return [];
  } catch (error) { }
};

export const fetchUserOwnedGroupIds = async (userId) => {
  try {
    const { data = [] } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-user-owned-groupids`, {
      userId: userId,
    });

    return data;
  } catch (error) {
    return null;
  }
};

export const saveWorkflowJobConfiguration = async (data) => {
  try {
    const response = await api.post(`${endpoints.manageWorkflowJobTypes}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getWorkflowJobConfiguration = async (workflow, schema, job_types, component_id) => {
  try {
    const response = await api.get(endpoints?.getWorkflowJobTypes, {
      workflow,
      schema,
      job_types,
      component_id,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const getPrivilegeMainSectionsData = async () => {
  try {
    const response = await api.get(endpoints?.getPrivilegeMainSectionList);
    return response;
  } catch (error) {
    return null;
  }
};

export const getPrivilegeSubSectionsData = async () => {
  try {
    const response = await api.get(endpoints?.getPrivilegeSubSectionList);
    return response;
  } catch (error) {
    return null;
  }
};

export const getAssignedPrivilegeSectionsData = async () => {
  try {
    const response = await api.get(endpoints?.getAssignedPrivilegeSectionList);
    return response;
  } catch (error) {
    return null;
  }
};

export const updatePrivilegeConfigurations = async (obj) => {
  const { data, mainSection, subSection } = obj;
  try {
    const response = await api.put(`${endpoints.updatePrivilegeConfigurationItems}`, { data, mainSection, subSection });
    return response;
  } catch (error) {
    return null;
  }
};

export const updateApplicatinControlTabs = async ({ applicationId, renderingTabs }: { applicationId: number; renderingTabs: [] }) => {
  try {
    // "renderingTabs": ["arm_allocation"]
    const response = await api.put(`${endpoints.updateApplicationTabs}`, {
      applicationId,
      renderingTabs,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const updateApplicatinInactiveTabs = async ({ applicationId, inactiveTabs }: { applicationId: number; inactiveTabs: [] }) => {
  try {
    const response = await api.put(`${endpoints.updateApplicationInactiveTabs}`, {
      applicationId,
      inactiveTabs,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const updateDifferApplications = async ({ applicationIds }: { applicationIds: [] }) => {
  try {
    const response = await api.post(`${endpoints.differApplicationsEndpoint}`, {
      applicationIds,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const getStageTasks = async () => {
  try {
    let data = await CachingStorage.invoke("stagesTasks", () => api.get(`${endpoints.stagesTasks}`, {}));
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    return data;
  } catch (error) {
    return null;
  }
};
// stagesOrder
export const getProductStageOrder = async () => {
  try {
    let data = CachingStorage.read("stagesOrder") || undefined;

    if (data) return CachingStorage.read("stagesOrder");

    data = await api.get(`${endpoints.stagesOrder}`, {});

    if (data?.data) {
      data = typeof data?.data === "string" ? JSON.parse(data?.data) : data?.data;
      CachingStorage.store("stagesOrder", data);
      return data;
    }

    return {};

  } catch (error) {
    return null;
  }
};

export const updateStageOrderConfig = async (order) => {
  try {
    const response = await api.post(`${endpoints.stagesOrder}`, order);
    if (response) {
      CachingStorage.clear("stagesOrder");
      return response;
    }
  } catch (error) {
    return null;
  }
};

export const getProductStageDeEvaluation = async (catalog = "") => {
  try {
    if (catalog) {
      return CachingStorage.invoke(CachingStorage.Keys.STAGE_DE + catalog, () => api.get(`${endpoints.stagesEvaluation}`, { catalog }));
    }
    return CachingStorage.invoke(CachingStorage.Keys.STAGE_DE, () => api.get(`${endpoints.stagesEvaluation}`, {}));
  } catch (error) {
    return null;
  }
};

export const getProductStageDocumentPermissions = async () => {
  try {
    const response = await CachingStorage.invoke("stageDocumentPermissions", () => api.get(`${endpoints.stageDocumentPermissions}`));
    return response;
  } catch (error) {
    return null;
  }
};

export const saveWorkflowStageTransferPreConditionCheckConfig = async (data) => {
  try {
    const response = await api.post(`${endpoints.stageTransferPreConditionCheckConfig}`, {
      ...data,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const saveStageDocumentProductConfig = async (data) => {
  try {
    const response = await api.post(`${endpoints.stageDocumentPermissions}`, {
      ...data,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteSelectedPrivilegePermission = async (data) => {
  try {
    let url = `${endpoints.stageDocumentPermissions}`;
    const qparam: any = [];
    if (data.catalog) {
      qparam.push("catalogId=" + data.catalog?.id);
    }
    if (data.selectedSection) {
      qparam.push("selectedSectionId=" + data.selectedSection?.id);
    }
    if (data.selectedStages) {
      qparam.push("selectedStageId=" + data.selectedStages?.id);
    }

    if (data.type) {
      qparam.push("type=" + data.type);
    }

    if (qparam.length > 0) {
      url = url + "?" + qparam.join("&");
    }

    const response = await api.delete(url);
    return response;
  } catch (error) {
    return null;
  }
};

export const getWorkflowStageTransferPreConditionCheckConfig = async (catalog) => {
  try {
    const response = await api.get(`${endpoints.getStageTransferPreConditionCheckConfig}`, { catalog });

    return response;
  } catch (error) {
    return null;
  }
};

export const getStageDocumentProductConfig = async () => {
  try {
    const response = await CachingStorage.invoke("privileges/permissions", () => api.get(`${endpoints.getStageDocumentProductConfig}`));
    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationDocuments = async (applicationId, individualId?, documentType?) => {
  try {
    const response = await api.get(endpoints?.documentUnLinked, {
      applicationId,
      individualId,
      documentType
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const updateProductDocuments = async (obj) => {
  const { applicationId, detachDocuments, approveRejectDocuments, indId, docType, docIndex } = obj;
  try {
    const response = await api.put(endpoints?.documentUnLinked, {
      applicationId,
      detachDocuments,
      approveRejectDocuments,
      participantType: indId,
      documentType: docType,
      documentIndex: docIndex,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const saveNewWatchlist = async (obj) => {
  try {
    const response = await api.post(endpoints?.watchlist.replaceAll("data-api", "/service/api/v1"), obj);
    return response;
  } catch (error) {
    return null;
  }
};

export const getNewWatchlist = async () => {
  try {
    const response = await api.get(endpoints?.watchlist.replaceAll("data-api", "/service/api/v1"), {});
    return response;
  } catch (error) {
    return null;
  }
};

export const getLoanPurposeConfiguration = async (data) => {
  try {
    const { sector, type, scheme } = data;
    const response = await CachingStorage.invoke("productLoanPurposeGetUrl" + `${sector}-${type}-${scheme}`, () => api.get(`${endpoints.productLoanPurposeMappingUrl}?sector=${sector}&type=${type}&scheme=${scheme}`, {}));

    return response;
  } catch (error) {
    return null;
  }
};

export const saveLoanPurposeConfiguration = async (data) => {
  try {
    const response = await api.post(`${endpoints.productLoanPurposeMappingUrl}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getExpenseTypeConfiguration = async (data) => {
  const { type, sector, scheme } = data;
  try {
    // const response = await api.get(`${endpoints.productExpenseMappingUrl}?sector=${data.sector}&type=${data.type}&scheme=${data.scheme}`, {});
    const response = await CachingStorage.invoke("productExpenseMappingUrl" + `${type}-${sector}-${scheme}`, () => api.get(`${endpoints.productExpenseMappingUrl}?sector=${data.sector}&type=${data.type}&scheme=${data.scheme}`, {}));

    return response;
  } catch (error) {
    return null;
  }
};

export const saveExpenseTypeConfiguration = async (data) => {
  try {
    const response = await api.post(`${endpoints.productExpenseMappingUrl}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getCreditDataByApplicationId = async (data) => {
  try {
    const response = await api.get(`${endpoints.creditDataByApplicationId}?applicationId=${data.applicationId}`);
    return response;
  } catch (error) {
    return null;
  }
};

export const getInspectionLimit = async (data) => {
  try {
    const response = await api.get(`${endpoints.inspectionLimitApi}?groups=${data.groups}`);
    return response;
  } catch (error) {
    return null;
  }
};

export const getInspectionEvaluationLimit = async (data) => {
  try {
    const response = await api.get(`${endpoints.inspectionLimitApi}?groups=${data.groups}`);
    return response;
  } catch (error) {
    return null;
  }
};

export const saveInspectionLimit = async (data) => {
  try {
    const res = await api.post(`${endpoints.inspectionLimitApi}`, data);
    return res;
  } catch (error) { }
};

export const saveEvaluationLimit = async (data) => {
  try {
    const res = await api.post(`${endpoints.inspectionEvaluationLimitApi}`, data);
    return res;
  } catch (error) { }
};

export const saveEvaluationCollateral = async (data) => {
  try {
    const res = await api.post(`${endpoints.inspectionEvaluationCollateral}`, data);
    return res;
  } catch (error) { }
};

export const fetchInspectionCollateral = async (data) => {
  try {
    const res = await api.get(`${endpoints.inspectionEvaluationCollateral}`, data);
    return res;
  } catch (error) { }
};

export const saveScorecardData = async (scorecard) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecard/template`, scorecard);

    return res;
  } catch (error) {
    return null;
  }
};
export const getScorecardTemplates = async () => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/scorecard/template`);
    if (res.status === 1) {
      return res.data;
    }
  } catch (error) {
    return null;
  }
};

export const deleteDLALimitsById = async (obj) => {
  try {
    const response = await api.delete(`${endpoints.fetchDLALimits}`, obj);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateReferralChangeAssignee = async (obj) => {
  try {
    const response = await api.patch(`${endpoints.referralChangeAssignee}`, obj);

    return response;
  } catch (error) {
    return null;
  }
}

export const forceChangeCurrentAssignee = async (obj) => {
  try {
    const response = await api.post(`${endpoints.forceChangeCurrentAssignee}`, obj);
    return response;
  } catch (error) {
    return null;
  }
}

export const forceChangeCurrentStage = async (obj) => {
  try {
    const response = await api.post(`${endpoints.forceChangeCurrentStage}`, obj);
    return response;
  } catch (error) {
    return null;
  }
};

export const resumeArchivedDocuments = async (params) => {
  try {
    const response = await api.put(endpoints?.archivedDocuments, params);

    return response;

  } catch (error: any) {
    return null;
  }
}

export const getArchivedDocuments = async (applicationId) => {
  try {
    const response = await api.get(endpoints?.archivedDocuments, {
      applicationId,
    });
    return response;
  } catch (error) {
    return null;
  }
};