import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { getApplicationScorecardList } from "../../../../../services/creditFileApiCall";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../UserCreditComponents/ErrorMessageComponent";
import AthamaruScoreCardLayout from "./AthamaruScoreCardLayout";
import RetailScoreCardLayout from "./RetailScoreCardLayout";

const ScoreCardLayout = ({ applicationId }) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const [scoreCardData, setScoreCardData] = useState<any>([]);
    const [totalScore, setTotalScore] = useState<any>(null);
    const [grade, setGrade] = useState<any>({});
    const [view, setView] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>(true);
    const [error, setError] = useState<any>("");

    useEffect(() => {
        prepareLayout();
    }, []);

    const prepareLayout = async () => {
        setError("");
        const { data: scoreCardDataResult, status } = await getApplicationScorecardList(applicationId);
        if (!scoreCardDataResult) {
            setIsLoading(false);
            return;
        }

        if (status < 1) {
            setIsLoading(false);
            if (status === -100) {
                setError("score card product mapping not found.");
            }
            return;
        }

        if (Object.keys(scoreCardDataResult).length > 0) {
            const { scoreCardData, view: _view, totalScore, grade } = scoreCardDataResult;
            let array: any = [];

            Object.keys(scoreCardData).map((item) => {
                array[item] = scoreCardData[item];
            });

            setScoreCardData(array);
            setTotalScore(totalScore);
            setGrade(grade);
            setView(_view);
        }
        setIsLoading(false);
    }

    const ToggelScoreCardLayout = () => {
        const props = { isLoading, scoreCardData, view, masterDataStore };

        if (isProductOf()) {
            return <AthamaruScoreCardLayout {...props} />
        }

        return <RetailScoreCardLayout {...props} />
    }

    if (isLoading) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"} height={"400px"}>
            <CircularProgressComponent
                size={30}
                sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    zIndex: 100,
                }}
            />
        </Stack>;
    }

    if (!grade.name) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"} height={"400px"}>
            <ErrorMessageComponent headMessage={error || "No data available"} errorMessage={""} />
        </Stack>;
    }

    return (
        <div className="full-width inner-component-full-height">
            <Stack p={1}>
                <Stack p={1} style={{ backgroundColor: `${grade?.color}3d` }}
                    className="border-radius-5">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">Overall Score
                    </div>
                </Stack>
                <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Stack direction={"row"} gap={40} p={3} alignItems={"center"}>
                        <Stack>
                            <Typography variant="caption" color={"gray"}>Total Score</Typography>
                            <Typography variant="h5" style={{ color: grade?.color }}>{totalScore && totalScore.toFixed(2)}</Typography>
                        </Stack>
                        <Stack>
                            <Typography variant="caption" color={"gray"}>Grade</Typography>
                            <Avatar round={true} value={grade?.name} size={"36px"} color={grade?.color} />
                        </Stack>
                    </Stack>
                </Stack>
                <ToggelScoreCardLayout />
            </Stack>
        </div>
    );
};

export default ScoreCardLayout;
